<!--CMS合同管理系统-->
<template>
  <div class="cms-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="CMS合同管理系统" @btnClick="bannerClick"
                  imgStyle="width:592px;height:357px" :imgSrc="`${require('@/assets/img/solution/CMS合同管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--传统合同管理面临的痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">传统合同管理面临的痛点</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="list of dataList">
              <el-col :span="8">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--合同管理六大核心能力-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">合同管理六大核心能力</p>
        <div class="module--content">
          <div class="module-left">
            <div class="module-li">
              <p class="title">高效文本处理的能力</p>
              <p class="content">多种合同模板便捷生成合同文本、限定编辑、关键词凸显、文本比对便捷确认文本内容；内外协同磋商快速达成一致</p>
            </div>
            <div class="module-li li-2">
              <p class="title">智能合规审核的能力</p>
              <p class="content">权责矩阵快速生成审批过程，部门协同各司其职；背景信息、智能合规审核，帮助提高审批效率</p>
            </div>
            <div class="module-li li-3">
              <p class="title">全程数字化的能力</p>
              <p class="content">通过数字身份、电子签章，实现合同审批用印一体化；合同所有信息数字化归档</p>
            </div>
          </div>
          <div class="module-center"/>
          <div class="module-right">
            <div class="module-li">
              <p class="title">全程风控自动预警能力</p>
              <p class="content">时刻监控合同风险，通过智能埋点，相对方资信变化、各种逾期，自动预警提醒</p>
            </div>
            <div class="module-li li-2">
              <p class="title">与业务系统协同的能力</p>
              <p class="content">合同交期条款等信息能够开放出来，指导项目、采购、生产执行；业务数据也能够作用于合同状态的变更；履约更清晰，收付更可控</p>
            </div>
            <div class="module-li li-3">
              <p class="title">丰富的数据分析的能力</p>
              <p class="content">提供合同签订、执行、风险等统计报表；合同数据与业务、财务数据协同，与资金支付、业绩核定等相互关联，自定义更多分析报表</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--合同管理产品亮点-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="color: #FFFFFF">合同管理产品亮点</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--帮企数智云CMS的优势-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云CMS的优势</p>
        <div class="module-col">
          <el-row :gutter="30">
            <template v-for="list of dataList2">
              <el-col :span="8">
                <div class="col-content">
                  <div class="col-img"
                       :style="'background: url('+require('@/assets/img/solution/'+list.icon)+') no-repeat'"/>
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'CMS合同管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";

export default {
  name: "cms",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerContent: '帮企数智云专注于合同管理的品牌，通过强大的低代码平台、流程引擎与丰富组件，落地合同审批规则，构建全程数字化的合同管理平台，让合同管理更加高效、合规、安全、协同。',
      dataList: [
        {title: '合同流转速度缓慢', introduce: '预审、签批、盖章“跑断腿”，合同在各签约方之间“满天飞”，严重影响交易效率'},
        {title: '合同规则落地困难', introduce: '合同模板很难进行统一控制，合同评审尺度不一致，权责不清晰，导致合同管理体系较难落地'},
        {title: '合同拟稿效率低下', introduce: '范本不全、反复修改及协商条款，业务法务信息不同步，致使合同拟稿效率低下'},
        {title: '合同执行跟踪不便', introduce: '合同执行未能全程在线跟踪，履约信息滞后、脱节，无法实时感知履约异常与风险'},
        {title: '合同与业务不协同', introduce: '合同的履行与实际的销售、采购业务进度以及收付款管理缺乏有效的关联与融合'},
        {title: '合同台账信息滞后', introduce: '合同数据分散在多个业务、财务等系统，缺少全面关联，导致合同台账信息滞后，无法通过合同数据洞察辅助经营决策'},
      ],
      dataList2: [
        {title: '强化业务协作', introduce: '建立合同起草、审批、履约、结算全流程数字化管理，加速业财法融合', icon: '强化业务协作.png'},
        {title: '数据驱动管理', introduce: '文本、审查记录、履约记录等全量数据结构化管理，实时洞察企业经营动态', icon: '数据驱动管理.png'},
        {title: '提升风险防控', introduce: '规范化、数字化合同管理体系，提升企业风险防控能力以及合规经营水平', icon: '提升风险防控.png'},
      ]
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.cms-container {
  background: #FFFFFF;

  .module-1 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 20px;
          height: 100px;

          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #1935DE;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }

          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
          }
        }
      }
    }
  }

  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module--content {
        display: flex;
        justify-content: space-between;

        .module-center {
          width: 483px;
          height: 357px;
          background: url("~@/assets/img/solution/合同管理六大核心能力.png");
          background-size: 100% 100%;
        }

        .module-left {
          width: 350px;
          margin-top: -10px;

          .module-li {
            text-align: end;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }

          .li-2 {
            margin-top: 62px;
          }

          .li-3 {
            margin-top: 77px;
          }
        }

        .module-right {
          width: 350px;
          margin-top: -10px;

          .module-li {
            text-align: left;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }

          .li-2 {
            margin-top: 85px;
          }

          .li-3 {
            margin-top: 77px;
          }
        }
      }
    }
  }

  .module-3 {
    background: url("~@/assets/img/solution/组1540.png") no-repeat;
    background-size: 100% 100%;
    height: 800px;
    width: 100%;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-img {
        height: 607px;
        width: 1200px;
        background: url("~@/assets/img/solution/合同管理产品亮点.png") no-repeat;
        background-size: 100% 100%;
        float: right;
      }
    }
  }

  .module-4 {
    background: #FFFFFF;
    height: 432px;
    width: 100%;
    padding-bottom: 60px;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .col-content {
        background: #FFFFFF;
        padding: 30px 20px;
        margin-bottom: 15px;
        border: 1px solid #DDDDDD;

        .default-title {
          font-size: 18px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          margin-block-end: 15px;
          margin-block-start: 10px;
        }

        .introduce {
          font-size: 16px;
          font-family: "SourceHanSansCN";
          color: #333333;
          margin-block-end: 0px;
          margin-block-start: 0px;
        }

        .col-img {
          width: 90px;
          height: 85px;
          background-size: 100% 100% !important;
        }
      }
    }
  }

  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
